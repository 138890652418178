export const TeenVogueLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    height="100%"
    viewBox="0 0 360 54"
    enableBackground="new 0 0 360 54"
    xmlSpace="preserve"
  >
    <path
      fill="#252525"
      d="M21.818,12.793h8.625l2.588-11.429H2.523L0.016,12.793h6.783L1.082,38.375
	c-0.29,1.744-0.678,3.585-0.678,5.33c0,5.021,2.246,7.876,5.275,9.373h16.806l2.435-10.73h-2.907c-2.714-0.097-5.62-0.29-5.62-4.457
	c0-0.969,0.388-2.81,0.678-4.264L21.818,12.793z"
    />
    <path
      fill="#252525"
      d="M36.985,52.804h24.938c6.505-3.128,9.721-8.372,11.908-11.909l-12.5-4.457
	c-1.454,2.52-4.361,7.461-12.21,7.461c-3.392,0-6.202-1.261-8.043-3.876c-1.549-2.229-1.647-4.361-1.647-8.333h36.337
	c0.194-2.229,0.388-4.361,0.388-6.59c0-16.376-11.435-22.771-23.062-22.771C33.23,2.328,24.8,19.673,24.8,31.398
	C24.8,39.654,28.39,48.515,36.985,52.804z M53.289,12.793c1.84,0,3.681,0.484,5.232,1.356c4.167,2.326,4.263,6.298,4.263,8.14
	H41.466C43.114,18.607,45.828,12.793,53.289,12.793z"
    />
    <path
      fill="#252525"
      d="M127.929,25.1c0-16.376-11.434-22.771-23.062-22.771c-19.864,0-28.294,17.345-28.294,29.07
	c0,8.253,3.587,17.11,12.178,21.4h24.955c6.499-3.128,9.712-8.368,11.898-11.904l-12.5-4.457c-1.454,2.52-4.36,7.461-12.209,7.461
	c-3.392,0-6.202-1.261-8.042-3.876c-1.551-2.229-1.647-4.361-1.647-8.333h36.337C127.735,29.461,127.929,27.328,127.929,25.1z
	 M93.239,22.29c1.648-3.683,4.361-9.497,11.822-9.497c1.84,0,3.682,0.484,5.232,1.356c4.167,2.326,4.264,6.298,4.264,8.14H93.239z"
    />
    <path
      fill="#252525"
      d="M162.718,2.813c-7.654,0-12.016,4.554-14.534,7.267l1.453-6.008h-15.019l-11.072,48.727h14.926l5.834-25.373
	c0.873-3.974,2.132-7.946,4.555-10.37c1.549-1.549,3.681-2.713,6.007-2.713c1.842,0,5.621,0.97,5.621,5.622
	c0,1.55-0.291,3.004-0.582,4.65l-6.232,28.184h14.931l7.192-31.478c0.291-1.841,0.679-3.683,0.679-5.523
	C176.479,7.27,170.277,2.813,162.718,2.813z"
    />
    <g>
      <path
        fill="#252525"
        d="M347.883,38.329c-0.963-7.404-2.711-11.029-7.961-11.708c-0.896,0.002-3.016,0.004-3.729,0.006
		c0.002,1.38-0.004,18.939,0.004,25.058c2.781,0.005,3.508-0.007,6.279,0.003c7.273-0.457,15.83-6.056,17.539-17.909
		c-0.008,5.971,0.002,12.099,0,18.499l-37.379,0.016l0.004-0.611l5.549,0.003c0.004-16.856-0.008-32.427-0.004-48.971l-3.945-0.006
		c0.016,13.367,0.016,24.315,0.004,38.205c-0.295,4.063-1.992,6.45-3.734,8.653c-2.291,2.658-4.992,3.343-6.318,3.677
		c-7.836,1.748-16.715,0.093-20.674-5.754c-1.033-1.852-1.666-2.856-1.764-9.087l0.006-35.684l-4.623-0.015l-0.008-0.586
		c5.943,0.019,11.256,0.014,17.324,0.009l-0.004,0.585l-4.691,0.006l-0.008,42.425c-0.121,2.237,1.445,5.063,5.455,7.354
		c2.236,0.985,5.855,0.742,8.02,0.338c1.652-0.202,2.686-0.678,4.115-1.372c2.842-1.396,6.398-6.754,6.326-10.513
		c-0.006-14.47-0.016-38.251-0.016-38.251l-5.957,0.01c-0.018-0.646,0.012-0.028,0.004-0.586l40.191,0.004l0.002,16.716
		c-0.461-2.043-0.805-5.225-2.537-8.359c-2.943-5.727-6.59-7.962-13.01-7.768l-6.143-0.002l-0.018,23.323
		c0.678,0,2.936,0.015,3.76-0.001c5.354-0.504,7.074-3.34,7.926-10.125L347.883,38.329z"
      />
      <path
        fill="#252525"
        d="M290.559,32.157c-1.205-0.01-2.057-0.005-2.998-0.01c0.006,5.873,0.006,13.181,0.004,19.772
		c-1.193-2.075-2.57-3.229-5.588-2.361c-4.236,2.022-9.326,4.115-12.188,4.043c-3.066,0.08-6.148-1.004-8.969-2.376
		c-9.973-6.72-13.625-19.255-11.039-31.362c1.869-7.711,7.389-16.291,15.508-18.876c8.121-2.963,13.604,5.553,19.127-0.445
		c0.006,4.289,0.006,16.326,0.006,18.061v0.603c-1.855-6.719-4.953-15.427-12.586-18.023c-4.637-0.997-8.775,1.083-10.729,4.629
		c-4.164,9.161-4.422,33.1,0.785,43.001c0.656,1.498,3.521,4.386,7.9,4.179c4.375-0.194,9.783-2.962,9.783-6.928
		c0.002-4.812,0.002-8.751-0.014-13.906c-0.801-0.001-2.596-0.002-3.006-0.007c0.002-0.283-0.012-0.272-0.002-0.598l14.012,0.007
		L290.559,32.157z"
      />
      <path
        fill="#252525"
        d="M236.898,50.221c-4.34,2.962-9.689,4.519-15.305,3.271c-7.021-1.479-13.422-8.084-15.764-14.774
		c-3.062-9.892-1.896-22.294,4.9-30.115c5.342-5.307,11.838-8.691,19.518-7.03c6.951,1.513,14.213,8.614,16.102,15.472
		C250.287,28.992,247.129,42.515,236.898,50.221z M233.334,4.387c-2.379-2.576-7.43-3.442-11.424-1.882
		c-7.174,3.736-7.713,13.355-8.344,21.398c-0.428,5.46-0.586,19.729,5.285,27.124c2.389,2.38,7.049,2.749,10.035,1.865
		c8.889-3.283,9.236-13.376,9.863-21.728l0.006-0.178C239.227,21.69,238.213,10.298,233.334,4.387z"
      />
      <path
        fill="#252525"
        d="M213.582,2.699c-0.135,0.042-4.332-0.003-4.9,0.015c-5.168,16.382-10.65,33.67-15.809,49.931
		c-1.082-2.608-2.018-5.023-3.078-7.892L174.11,2.702c-1.771,0.005-2.254,0.017-3.646-0.002l-0.013-0.576l16.084,0.008l-0.01,0.578
		l-3.814,0.001l13.404,36.228l0.234,0.698c3.84-12.007,7.873-24.62,11.809-36.922c-1.799-0.016-3.725-0.006-5.35-0.002l0.006-0.585
		l10.756-0.012L213.582,2.699z"
      />
    </g>
  </svg>
);
