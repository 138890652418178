export const CNTLogo = () => (
  <svg
    width="99"
    height="35"
    viewBox="0 0 99 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M15.6333 0.28717H0V3.76923H5.25897V34.6051H10.5179V3.76923H15.6333V0.28717Z"
        fill="#252525"
      />
      <path
        d="M22.0231 8.81283L21.8975 8.79488C19.8154 8.48975 17.7693 9.81796 17.0872 10.3205V9.01027H12.4026V34.5333H17.3026V34.3898C17.3026 34.2282 17.3564 17.5718 17.3026 15.7769C17.2308 13.7667 20.3359 13.6051 20.3539 13.6051H22.0231V8.81283Z"
        fill="#252525"
      />
      <path
        d="M45.0333 9.01029L42.5385 24.4282L39.8282 8.93848H34.7308L39.7385 34.6051H43.6872L48.3897 9.01029H45.0333Z"
        fill="#252525"
      />
      <path
        d="M61.9052 22.1666V22.0231C62.1924 16.5666 61.2052 12.6897 58.9616 10.4641C56.9872 8.50767 54.7975 8.63331 54.6898 8.63331C47.0795 9.58459 47.5103 19.5102 47.5103 19.6C47.5103 19.6179 47.3667 22.041 47.6898 24.8949C48.1206 28.7538 49.1436 31.5359 50.7411 33.1692C51.8001 34.2641 53.1103 34.8025 54.636 34.8025C54.7078 34.8025 54.7975 34.8025 54.8693 34.8025C56.9693 34.7307 58.6565 33.9769 59.8411 32.5769C61.2231 30.9436 61.7078 28.7 61.8513 27.1025C62.0129 25.3795 61.8513 24.0333 61.8513 24.0154L61.8334 23.8897H56.6821V29.6333C56.6821 29.6513 56.6283 31.3384 55.8206 32.0923C55.5334 32.3615 55.1924 32.4872 54.7975 32.4692C52.9847 32.3974 52.7513 29.6513 52.7513 29.6333V22.1487H61.9052V22.1666ZM53.6308 11.2538C53.9001 10.9666 54.2411 10.8231 54.636 10.8231C54.7078 10.8231 56.4667 10.9846 56.5206 14.3948C56.5744 17.4461 56.5385 19.3307 56.5206 19.8513H52.7693V14.3231C52.7513 14.3051 52.7334 12.1872 53.6308 11.2538Z"
        fill="#252525"
      />
      <path
        d="M88.182 22.1666V22.0231C88.4692 16.5666 87.482 12.6897 85.2385 10.4641C83.2641 8.50767 81.0744 8.63331 80.9667 8.63331C73.3564 9.58459 73.7872 19.5102 73.7872 19.6C73.7872 19.6179 73.6436 22.041 73.9667 24.8949C74.3974 28.7538 75.4205 31.5359 77.0179 33.1692C78.0769 34.2641 79.3872 34.8025 80.9128 34.8025C80.9846 34.8025 81.0744 34.8025 81.1461 34.8025C83.2461 34.7307 84.9333 33.9769 86.1179 32.5769C87.5 30.9436 87.9846 28.7 88.1282 27.1205C88.2897 25.3974 88.1282 24.0513 88.1282 24.0333L88.1102 23.9077H82.959V29.6513C82.959 29.6692 82.9051 31.3564 82.0974 32.1102C81.8102 32.3795 81.4692 32.5051 81.0744 32.4872C79.2615 32.4154 79.0282 29.6692 79.0282 29.6513V22.1666H88.182ZM79.9077 11.2538C80.1769 10.9666 80.5179 10.8231 80.9128 10.8231C81.0205 10.8231 82.7436 10.9846 82.8154 14.3948C82.8692 17.4461 82.8333 19.3307 82.8154 19.8513H79.0282V14.3231C79.0461 14.3051 79.0102 12.1872 79.9077 11.2538Z"
        fill="#252525"
      />
      <path d="M67.2898 0H62.5693V34.6051H67.2898V0Z" fill="#252525" />
      <path d="M73.141 0H68.4205V34.6051H73.141V0Z" fill="#252525" />
      <path
        d="M98.4128 8.50771C96.3308 8.20258 94.2846 9.53079 93.6026 10.0334V8.72309H88.918V34.6051H93.818V34.4616C93.818 34.2821 93.8 17.2846 93.818 15.4898C93.818 14.6462 94.3385 14.0359 95.3257 13.641C96.0975 13.3359 96.8693 13.3 96.8693 13.3H98.5385V8.52566L98.4128 8.50771Z"
        fill="#252525"
      />
      <path
        d="M33.0795 10.3026C31.2487 8.59744 28.9692 8.70511 28.8256 8.72306C26.582 8.75896 24.8769 9.47692 23.7103 10.8051C21.7 13.1564 22.1128 16.5846 22.1308 16.7282L22.1487 16.8538H26.9769V13.3897C26.9949 13.2282 27.3359 11.0564 28.7538 11.0564C30.2077 11.0564 30.4051 13.2462 30.4051 13.2641V16.6205C30.2077 16.9077 29.3103 17.6077 28.7538 18.0205C28.5385 18.1641 28.3051 18.3256 28 18.541C27.4974 18.9 26.8154 19.3846 25.7026 20.1385C23.4949 21.6103 22.5256 23.1718 22.4897 23.2436C21.0538 25.541 21.4487 30.8359 22.2385 32.3795C22.6333 33.1513 23.8538 34.9282 26.5103 34.9282C28.6641 34.9282 30.1538 33.8513 30.7282 33.3667V34.641H35.3051V16.7102C35.3051 13.8384 34.5513 11.6846 33.0795 10.3026ZM30.4769 29.1846C30.459 29.3103 30.2436 31.2128 28.7359 31.2308C28.7179 31.2308 28.7179 31.2308 28.7 31.2308C27.1205 31.2308 26.6718 29.6154 26.6179 29.4179V23.3154C26.6179 23.3154 26.6718 22.5615 27.0846 22.0949C27.3179 21.8436 28.1436 21.341 28.9333 20.8385C29.5256 20.4795 30.1 20.1205 30.459 19.8513V29.1846H30.4769Z"
        fill="#252525"
      />
      <path
        d="M19.582 7.87946C20.6589 7.87946 21.3051 7.28715 21.6641 6.78459C21.9872 6.33587 22.0769 5.95895 22.1487 5.59997L22.1846 5.45638L20.7846 5.02562L20.7487 5.13331C20.4077 6.24613 19.7077 6.35382 19.4205 6.35382C18.5051 6.35382 18.2 5.09741 18.2 4.03844C18.2 3.60767 18.2718 1.47178 19.5461 1.47178C19.8872 1.47178 20.4795 1.61537 20.6769 2.58459L20.6948 2.71023L22.1128 2.26151L22.0948 2.15382C21.9154 1.36409 21.1795 0.0358812 19.6 0.05383C17.7872 0.05383 16.6025 1.63332 16.6025 4.09228C16.6025 5.40254 16.9436 6.40767 17.6077 7.07177C18.3077 7.77177 19.1333 7.87946 19.582 7.87946Z"
        fill="#252525"
      />
      <path
        d="M24.536 7.82565C25.6488 7.82565 26.7616 6.94616 26.7616 4.98975C26.7616 2.47693 25.1103 2.2077 24.4103 2.2077C23.118 2.2077 22.2744 3.30257 22.2744 5.0077C22.2744 6.94616 23.4052 7.82565 24.536 7.82565ZM24.536 3.30257C25.3077 3.30257 25.3436 4.61283 25.3436 4.88206C25.3436 5.68977 25.2001 6.65898 24.518 6.65898C23.7821 6.65898 23.6924 5.52822 23.6924 5.0436C23.6924 4.23591 23.836 3.30257 24.536 3.30257Z"
        fill="#252525"
      />
      <path
        d="M28.5205 4.09232C28.9513 3.66155 29.2205 3.50001 29.5077 3.50001C29.6692 3.50001 29.8307 3.55386 29.8307 3.98463V7.73591H31.2487V3.66155C31.2487 2.45898 30.6025 2.2077 30.0461 2.2077C29.3102 2.2077 28.8256 2.56668 28.5205 2.85386V2.29745H27.1025V7.73591H28.5205V4.09232Z"
        fill="#252525"
      />
      <path
        d="M33.4205 7.82565C33.9769 7.82565 34.3538 7.53847 34.6051 7.30514V7.73591H36.0231V0.0897522H34.6051V2.72821C34.3359 2.45898 33.941 2.22565 33.4384 2.22565C32.3436 2.22565 31.5538 3.41027 31.5538 5.0436C31.5538 6.64103 32.3436 7.82565 33.4205 7.82565ZM33.8872 3.53591C34.1384 3.53591 34.3718 3.6795 34.6051 3.96668V6.06668C34.4795 6.22821 34.2461 6.49744 33.9051 6.49744C33.0974 6.49744 33.0795 5.18719 33.0795 5.0436C33.0795 4.48719 33.1872 3.53591 33.8872 3.53591Z"
        fill="#252525"
      />
      <path
        d="M40.5821 0.125641H38.8231L37.4769 1.86666H38.6436L40.5821 0.125641Z"
        fill="#252525"
      />
      <path
        d="M38.7334 7.82565C40.0616 7.82565 40.5821 6.78463 40.7436 6.42565L40.7975 6.31798L39.6308 5.77952L39.5769 5.90516C39.4693 6.12054 39.2718 6.55129 38.7513 6.55129C38.518 6.55129 37.9616 6.42565 37.8898 5.36668H40.8513V5.25898C40.8872 4.75642 40.8872 4.3077 40.7257 3.73334C40.4564 2.80001 39.6667 2.2077 38.6975 2.2077C37.3154 2.2077 36.4 3.32052 36.4 4.95386C36.4 6.92822 37.6205 7.82565 38.7334 7.82565ZM38.6975 3.32052C38.8231 3.32052 39.4693 3.37437 39.559 4.3436H37.8718C37.9795 3.41027 38.5359 3.32052 38.6975 3.32052Z"
        fill="#252525"
      />
      <path
        d="M44.8718 2.92566L47.4744 7.73591H48.9462V0.197449H47.4205V4.81027L44.9077 0.197449H43.3641V7.73591H44.8718V2.92566Z"
        fill="#252525"
      />
      <path
        d="M49.4667 6.21026C49.4667 7.17949 50.2385 7.68205 50.9923 7.68205C51.5846 7.68205 51.9795 7.41282 52.2128 7.19744C52.2308 7.28718 52.2487 7.39487 52.2846 7.50257L52.3026 7.59231H53.6308L53.5769 7.44872C53.5051 7.23334 53.4154 6.74872 53.4154 6.47949V4.16411C53.4154 2.58462 52.2128 2.35129 51.4949 2.35129C50.7231 2.35129 50.059 2.67436 49.5923 3.30257L49.5385 3.39231L50.3821 4.16411L50.4539 4.05642C50.741 3.62565 51.0821 3.41026 51.4949 3.41026C51.8897 3.41026 52.141 3.66154 52.141 4.07436V4.12821C50.4359 4.3077 49.4667 5.06154 49.4667 6.21026ZM52.141 6.12052C51.9615 6.26411 51.5667 6.60513 51.2077 6.60513C50.9923 6.60513 50.759 6.44359 50.759 6.10257C50.759 5.49231 51.5128 5.22308 52.141 5.07949V6.12052Z"
        fill="#252525"
      />
      <path
        d="M55.7846 6.62306C55.2462 6.62306 54.8872 6.33588 54.6359 6.10255L54.5641 6.04871L53.6667 6.73076L53.7385 6.8205C54.0077 7.14358 54.5821 7.80768 55.7487 7.80768C57.1667 7.80768 57.8128 6.91024 57.8128 6.01281C57.8128 4.7923 56.6641 4.52306 56.0359 4.37947L56.018 4.48717V4.37947C55.4975 4.25383 55.1744 4.16409 55.1744 3.80512C55.1744 3.55383 55.3898 3.30255 55.7667 3.30255C56.0539 3.30255 56.2693 3.42819 56.6462 3.67948L56.8436 3.80512L57.6872 3.01537L57.5795 2.92563C57.2385 2.63845 56.6641 2.17178 55.7487 2.17178C54.6718 2.17178 53.8282 2.92563 53.8282 3.89486C53.8282 4.84614 54.4205 5.43845 55.6051 5.65383C56.1616 5.76153 56.3949 5.90512 56.3949 6.17435C56.4128 6.62306 55.8385 6.62306 55.7846 6.62306Z"
        fill="#252525"
      />
      <path
        d="M58.5846 6.13846C58.5846 6.74872 58.8179 7.26923 59.2128 7.55641C59.5 7.77179 59.841 7.82564 60.2718 7.82564C60.4154 7.82564 60.559 7.82564 60.7205 7.80769H60.8282V6.51538H60.7205C60.182 6.49744 60.0026 6.44359 60.0026 6.13846V3.48205H60.8282V2.31538H60.0026V0.61026H58.5846V2.29744H57.8666V3.4641H58.5846V6.13846Z"
        fill="#252525"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="98.5385" height="35" fill="#252525" />
      </clipPath>
    </defs>
  </svg>
);
