import Modal from '../Modal';
import { GalleryExternalIcon, FormationLightLogoFlatIcon } from '@condenast/gemini/icons';
import darkModeLogo from '../../assets/images/darkModeLogo.png';
import lightModeLogo from '../../assets/images/lightModeLogo.png';

export const HelpModal = ({ showModal, mode, closeModal }) => {
  const helpModalHeader = (
    <div className="flex border-b border-gray-5 p-5   rounded-t justify-between dark:text-white dark:bg-gray-800">
      <div className="flex font-ibm-sans text-black overflow-hidden text-ellipsis text-[22px] normal font-[400] leading-[24px] dark:text-white">
        <FormationLightLogoFlatIcon size='small' className='mr-2'/> Beacon Help Center
      </div>
      <div
        className="w-[24px] h-[24px] text-gray-1 dark:text-white cursor-pointer"
        onClick={() => {
          closeModal(!showModal);
        }}
      >
        &#x2715;
      </div>
    </div>
  );

  const helpModalBody = (
    <div className="relative py-[16px] px-[24px]  flex-auto dark:text-white dark:bg-gray-800">
      <div className="inline-flex font-ibm-sans pb-[16px]  text-black overflow-hidden text-ellipsis text-[16px] normal font-[500] leading-[20px] dark:text-white ">
        {' '}
        {mode === 'white' ? (
          <img src={darkModeLogo} className=" pl-5 px-2" />
        ) : (
          <img src={lightModeLogo} className="pl-5 px-2" />
        )}
        What is Beacon ?
      </div>
      <p className="font-ibm-sans pl-5 px-2 pb-[16px]  text-gray-1 overflow-hidden text-ellipsis text-[16px] normal font-[400] leading-[24px] dark:text-white">
      The Beacon Help center contains tutorials about how to use different features of Beacon, as well as explainers for purpose of Beacon, Data sources used, different tracking methodologies.
        <div className='py-2'>
            <a href="https://intercom.help/formation/en/collections/9980494-beacon-help" rel="noreferrer" target="_blank" className='flex flex-row text-[16px] text-blue-40 font-[700] normal leading-[24px] dark:text-blue-60 dark:hover:text-blue-60' 
            onClick={() => {
                closeModal(!showModal);
            }}>Visit help center <GalleryExternalIcon size="larger" /></a>
        </div>
      </p>
      <p></p>
    </div>
  );
  return (
    <>
      {showModal && (
        <Modal
          children={{ header: helpModalHeader, body: helpModalBody }}
          helpModal={showModal}
        />
      )}
    </>
  );
};
