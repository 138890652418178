export const GlamourLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 567.2 128.3"
    xmlSpace="preserve"
    width="100%"
    height="100%"
  >
    <g>
      <polygon points="131.1,118.5 111.6,118.5 111.7,0 86.8,0 86.8,125.8 112.2,125.8 112.2,125.8 131.1,125.8 	" />
      <g>
        <path d="M165.4,0L136,125.8l7.5,0l12.7-54.5h25l10.4,54.5h24.8L188.5,0H165.4 M158,64.1l12.1-51.6l9.8,51.6H158z" />
      </g>
      <polyline
        points="288.1,0 268,86.7 250,0 229.9,0 229.9,0 222.6,0.1 222.6,125.8 229.9,125.8 229.9,17.4 234.4,35.3 254.7,125.8 
		265.4,125.8 266.6,125.8 290.3,24.4 290.3,125.8 315.2,125.8 315.2,0 288.1,0 	"
      />
      <path
        d="M348.4,40.2c0,0-0.2,16.5-0.2,23.1c0,6.6,0.2,22.3,0.2,22.3c0,18.5,4.5,32.9,17,32.9c12.4,0,17-14.4,17-32.9
		c0,0,0.2-15.7,0.2-22.3c0-6.6-0.2-23.1-0.2-23.1c0-18.5-4.5-32.8-17-32.8C352.9,7.3,348.4,21.6,348.4,40.2 M407.4,62.8
		c0,61-35.3,63-42,63c-6.7,0-42-1.8-42-62.9S358.6,0,365.3,0C372.1,0,407.4,1.7,407.4,62.8"
      />
      <path
        d="M459.6,117.2c19.2,0,17.6-34.4,17.6-34.4c0-9.4,0-82.1,0-82.8h7.3c0,0.7,0.7,73.4,0.7,82.8c0,1.1,0,2.2,0,3.3
		c-0.3,10.6-1.7,23-9.2,30.8c-0.5,0.5-1,1-1.6,1.5c-8.3,7.2-21.5,8.2-31.7,6.7c-10.3-1.6-19.9-7.3-23.9-18c-3-8.1-3.2-16.9-3.2-25.5
		c0-0.1,0-81.6,0-81.6h24.8v90.6c0,0.1,0,0.2,0,0.3C440.5,90.9,440.1,117.2,459.6,117.2z"
      />
      <path
        d="M548.8,69.5c3.6-1.5,6.9-3,9.5-6c7.8-9.4,7.8-19.5,7.8-28.4c0-17.2-8.2-29-23.2-33.6c-0.2-0.1-0.4-0.1-0.7-0.2
		c-0.1,0-0.1,0-0.2-0.1l0,0c0,0-0.1,0-0.1,0c-0.2-0.1-0.4-0.1-0.6-0.2c0,0,0,0,0,0c-0.2-0.1-0.4-0.1-0.7-0.1c0,0-0.1,0-0.1,0
		c-0.2,0-0.4-0.1-0.7-0.1c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.7-0.1c-0.1,0-0.1,0-0.2,0c-0.2,0-0.5-0.1-0.7-0.1c0,0-0.1,0-0.1,0
		c-0.2,0-0.4,0-0.6-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.6-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c0,0-0.1,0-0.1,0
		c-0.2,0-0.4,0-0.5,0c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c-0.2,0-0.3,0-0.5,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4,0
		c0,0,0,0-0.1,0c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0
		c-0.1,0-0.1,0-0.1,0l-14.4,0l0,0h-24.7v125.8h25l0-54.5h6.2l16,54.3l0.1,0.2h26.3L548.8,69.5z M524,64c-0.5,0-5.7,0-5.7,0
		c0-7.1,0-56.4,0-56.3c2.1,0,4.8,0.2,6.9,0.6c13.3,2.7,18.2,16.3,18.4,27.8c0.1,6-0.2,12.6-2.5,18.3c-0.6,1.6-1.7,3-2.8,4.4
		c-1.1,1.3-2.3,2.7-3.8,3.5C531.4,64,527.5,64,524,64"
      />
      <path
        d="M69.5,15.8c3.2,4.9,6.7,16,6.7,32c-6.1,0-16.5,0.1-21.8,0.1v-1.4c0-5.1-0.1-6.1-0.1-6.1c0-18.7-4.1-32.8-13.4-32.8
		c-11.6,0-15.8,14.1-15.8,32.8l0,36.8c0,5.2,0.1,9.6,0.1,9.6c0,19.1,4.9,32.5,16.8,32.5c11.9,0,15.6-13.4,15.6-32.5l0.1-1
		c0.1-4.4,0.2-8,0.1-14.6c-5.8,0-12.8,0-18.6,0c0-1.7,0-5.6,0-7.3c12.9,0,25.8,0.1,38.7,0.1c0,21.6,0,40.1,0,61.7
		c-4.9,0-10.6,0-15.4,0c-0.2-20.9,0.2-13.9-0.2-20.9c-1.1,7.9-5.1,15.7-11.9,19.9c-7.3,4.6-17.1,4.4-24.9,0.7
		c-7.8-3.7-13.8-10.6-17.5-18.2c-3.8-7.8-5.6-16.5-6.7-25.1c-0.9-7.2-1.3-14.4-1-21.6C0.7,43.1,4.5,22.2,18.3,9.9
		C24.3,3.8,32.5,0,41.4,0c8.3,0,15.9,3.2,21.8,8.5c0,0,0.1,0.1,0.2,0.2C65.6,10.7,67.6,13,69.5,15.8z"
      />
    </g>
  </svg>
);
