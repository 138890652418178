export const TheWorldOfInteriorLogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 2200 470"
    xmlSpace="preserve"
	width="100%"
	height="100%"
  >
    <g>
      <path
        d="M2018.08,453.67h10.51c2.1-9.25,5.89-13.04,11.36-13.04c10.93,0,28.18,13.88,57.62,13.88c48.79,0,82.43-31.12,82.43-78.65
		c0-82.01-125.33-102.62-125.33-160.24c0-22.71,15.98-37.43,39.53-37.43c25.65,0,51.31,21.87,58.46,70.23h10.93v-82.85h-8.83
		c-2.1,7.57-4.21,10.93-10.93,10.93c-13.46,0-28.18-10.51-55.09-10.51c-45,0-73.6,31.54-73.6,67.29
		c0,82.01,124.91,99.25,124.91,163.6c0,26.5-13.88,45.42-42.48,45.42c-36.59,0-60.98-29.86-68.55-79.07h-10.93V453.67z
		 M1828.4,186.61c5.05-0.42,9.67-0.84,17.24-0.84c32.38,0,54.67,20.61,54.67,56.36c0,45.42-22.29,66.45-71.92,66.45V186.61z
		 M1743.87,449.04h124.49v-7.99c-33.65-2.52-39.95-16.82-39.95-53.83v-66.87c26.07,0,32.8,3.36,48.79,39.53
		c13.04,29.44,21.87,57.2,45,89.16h73.6v-7.99c-23.13-1.26-36.59-13.04-50.05-43.32c-15.98-36.17-26.5-69.39-50.05-84.11
		c35.33-13.04,52.99-37.43,52.99-67.71c0-56.36-47.1-74.44-97.15-74.44h-107.66v7.99c31.96,3.79,39.11,13.88,39.11,52.57v155.19
		c0,35.75-5.89,51.31-39.11,53.83V449.04z M1578.59,442.32c-62.66,0-100.09-49.63-100.09-132.9c0-79.07,37.43-131.22,102.2-131.22
		c56.36,0,99.67,46.26,99.67,132.9C1680.37,381.33,1648.4,442.32,1578.59,442.32 M1425.93,309.42
		c0,87.48,68.97,145.52,153.09,145.52c84.95,0,153.93-58.04,153.93-145.94c0-88.32-67.71-143.41-153.09-143.41
		C1495.74,165.58,1425.93,223.62,1425.93,309.42 M1290.51,449.04h124.91v-7.99c-32.38-2.94-39.53-12.62-39.53-60.56V240.86
		c0-50.05,5.89-57.2,39.53-61.4v-7.99h-124.91v7.99c33.65,4.21,39.53,11.36,39.53,61.4v139.63c0,47.94-7.15,57.62-39.53,60.56
		V449.04z M1102.52,186.61c5.05-0.42,9.67-0.84,17.24-0.84c32.38,0,54.67,20.61,54.67,56.36c0,45.42-22.29,66.45-71.92,66.45V186.61
		z M1017.99,449.04h124.49v-7.99c-33.65-2.52-39.95-16.82-39.95-53.83v-66.87c26.08,0,32.8,3.36,48.79,39.53
		c13.04,29.44,21.87,57.2,45,89.16h73.6v-7.99c-23.13-1.26-36.59-13.04-50.05-43.32c-15.98-36.17-26.5-69.39-50.05-84.11
		c35.33-13.04,52.99-37.43,52.99-67.71c0-56.36-47.1-74.44-97.15-74.44h-107.66v7.99c31.96,3.79,39.11,13.88,39.11,52.57v155.19
		c0,35.75-5.89,51.31-39.11,53.83V449.04z M779.95,449.04h192.2l15.14-79.91h-10.93c-15.98,47.52-39.53,66.03-66.03,66.03h-19.77
		c-20.61,0-26.08-4.63-26.08-28.6V309h27.76c31.12,0,40.79,19.35,42.9,41.64h10.51v-95.47h-10.51
		c-2.94,25.65-10.51,40.79-42.06,40.79h-28.6V185.35h40.37c41.64,0,50.89,18.93,57.2,52.15h10.93l-2.94-66.03h-190.1v7.99
		c31.96,3.79,39.11,13.88,39.11,52.57v155.19c0,35.75-5.89,51.31-39.11,53.83V449.04z M565.04,449.04h128.69v-7.99
		c-33.65-2.94-41.64-11.78-41.64-60.56V185.35h31.96c32.38,0,48.36,15.98,52.99,52.15h10.93v-66.03h-237.2v66.03h10.93
		c4.63-36.17,20.61-52.15,52.99-52.15h31.96v195.14c0,48.79-7.99,57.62-41.64,60.56V449.04z M170.14,449.04h110.19v-7.99
		c-36.17-2.94-46.68-14.72-46.68-51.31V229.51l198.09,224.16h8.83v-222.9c0-36.59,10.51-48.37,46.68-51.31v-7.99H377.06v7.99
		c36.17,2.94,46.68,14.72,46.68,51.31V370.4L247.52,171.47h-77.38v7.99c34.91,2.94,46.68,16.82,46.68,53.41v156.87
		c0,36.59-10.51,48.37-46.68,51.31V449.04z M20,449.04h124.91v-7.99c-32.38-2.94-39.53-12.62-39.53-60.56V240.86
		c0-50.05,5.89-57.2,39.53-61.4v-7.99H20v7.99c33.65,4.21,39.53,11.36,39.53,61.4v139.63c0,47.94-7.15,57.62-39.53,60.56V449.04z"
      />
      <path
        d="M1508.12,101.84h39.01V99.4c-11.59-0.77-13.13-5.15-13.13-16.48V60.26h7.21c9.53,0,12.49,5.92,13.13,12.75h3.22V43.78
		h-3.22c-0.9,7.85-3.22,12.49-12.87,12.49h-7.47V21.12h11.07c12.75,0,15.58,5.79,17.51,15.96h3.35l-0.9-20.21h-56.91v2.45
		c9.78,1.16,11.97,4.25,11.97,16.09v47.51c0,10.94-1.8,15.71-11.97,16.48V101.84z M1455.33,99.78c-19.18,0-30.64-15.19-30.64-40.68
		c0-24.2,11.46-40.17,31.28-40.17c17.25,0,30.51,14.16,30.51,40.68C1486.49,81.11,1476.7,99.78,1455.33,99.78 M1408.6,59.1
		c0,26.78,21.11,44.55,46.86,44.55c26.01,0,47.12-17.77,47.12-44.67c0-27.04-20.73-43.9-46.86-43.9
		C1429.97,15.07,1408.6,32.83,1408.6,59.1 M1311.14,22.28c6.18-0.51,10.04-0.64,14.68-0.64c23.56,0,34.37,16.22,34.37,37.72
		c0,24.59-14.81,38.24-34.76,38.24h-6.44c-6.44,0-7.85-1.8-7.85-8.88V22.28z M1285.26,101.84h42.61c31.16,0,48.41-18.8,48.41-42.23
		c0-30.51-21.5-42.74-49.18-42.74h-41.84v2.45c9.78,1.16,11.97,4.25,11.97,16.09v47.51c0,10.94-1.8,15.71-11.97,16.48V101.84z
		 M1216.38,101.84h58.84l4.63-24.46h-3.35c-4.89,14.55-12.1,20.21-20.21,20.21h-6.05c-6.31,0-7.98-1.42-7.98-8.75V35.41
		c0-11.84,1.67-14.93,12.62-16.09v-2.45h-38.49v2.45c9.78,1.16,11.97,4.25,11.97,16.09v47.51c0,10.94-1.8,15.71-11.97,16.48V101.84z
		 M1159.74,21.51c1.54-0.13,2.96-0.26,5.28-0.26c9.91,0,16.74,6.31,16.74,17.25c0,13.9-6.82,20.34-22.02,20.34V21.51z
		 M1133.86,101.84h38.11V99.4c-10.3-0.77-12.23-5.15-12.23-16.48V62.45c7.98,0,10.04,1.03,14.93,12.1
		c3.99,9.01,6.69,17.51,13.78,27.29h22.53V99.4c-7.08-0.39-11.2-3.99-15.32-13.26c-4.89-11.07-8.11-21.24-15.32-25.75
		c10.81-3.99,16.22-11.46,16.22-20.73c0-17.25-14.42-22.79-29.74-22.79h-32.96v2.45c9.78,1.16,11.97,4.25,11.97,16.09v47.51
		c0,10.94-1.8,15.71-11.97,16.48V101.84z M1080.05,99.78c-19.18,0-30.64-15.19-30.64-40.68c0-24.2,11.46-40.17,31.28-40.17
		c17.25,0,30.51,14.16,30.51,40.68C1111.2,81.11,1101.42,99.78,1080.05,99.78 M1033.31,59.1c0,26.78,21.11,44.55,46.86,44.55
		c26.01,0,47.12-17.77,47.12-44.67c0-27.04-20.73-43.9-46.86-43.9C1054.69,15.07,1033.31,32.83,1033.31,59.1 M946.41,104.16h2.57
		l22.66-62.57l24.98,62.57h2.57l20.6-67.33c3.48-11.2,6.05-16.99,14.16-17.51v-2.45h-25.11v2.45c5.92,1.03,8.37,2.7,8.37,7.21
		c0,2.19-0.64,4.38-2.57,10.94l-12.62,42.49l-17.64-44.55c-1.67-4.25-3.22-8.5-3.22-10.81c0-3.73,2.7-4.89,8.5-5.28v-2.45h-34.12
		v2.45c4.63,0.26,7.6,0.9,9.27,4.89l4.63,11.33l-16.09,44.29l-15.45-45.06c-1.8-5.41-2.57-8.75-2.57-10.17
		c0-4.38,5.02-5.28,8.37-5.28v-2.45h-34.5v2.45c7.72,0,9.78,5.15,12.87,14.16L946.41,104.16z M815.48,101.84h58.84l4.63-24.46h-3.35
		c-4.89,14.55-12.1,20.21-20.21,20.21h-6.05c-6.31,0-7.98-1.42-7.98-8.75V58.97h8.5c9.53,0,12.49,5.92,13.13,12.75h3.22V42.49h-3.22
		c-0.9,7.85-3.22,12.49-12.87,12.49h-8.75V21.12h12.36c12.75,0,15.58,5.79,17.51,15.96h3.35l-0.9-20.21h-58.19v2.45
		c9.78,1.16,11.97,4.25,11.97,16.09v47.51c0,10.94-1.8,15.71-11.97,16.48V101.84z M710.56,101.84h37.85V99.4
		c-10.17-0.77-11.97-5.54-11.97-16.48V59.48h43.39v23.43c0,10.94-1.8,15.71-11.97,16.48v2.45h37.85V99.4
		c-10.17-0.77-11.97-5.54-11.97-16.48V35.41c0-11.84,2.19-14.93,11.97-16.09v-2.45h-37.85v2.45c9.78,1.16,11.97,4.25,11.97,16.09
		v19.05h-43.39V35.41c0-11.84,2.19-14.93,11.97-16.09v-2.45h-37.85v2.45c9.78,1.16,11.97,4.25,11.97,16.09v47.51
		c0,10.94-1.8,15.71-11.97,16.48V101.84z M645.8,101.84h39.4V99.4c-10.3-0.9-12.75-3.6-12.75-18.54V21.12h9.78
		c9.91,0,14.81,4.89,16.22,15.96h3.35V16.87h-72.61v20.21h3.35c1.42-11.07,6.31-15.96,16.22-15.96h9.78v59.74
		c0,14.93-2.45,17.64-12.75,18.54V101.84z"
      />
    </g>
  </svg>
);
