import React from 'react';
import SiteDrop from './SiteDrop';

export default function Modal({
  siteModal = false,
  setSiteModal,
  filters,
  setFilters,
  children,
  helpModal = false,
}) {

  return (
    <>
      {siteModal || helpModal ? (
        <>
          <div className="justify-center   items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto max-w-[40rem]">
              {/*content*/}
              <div className="border-0 rounded-sm shadow-sm relative flex flex-col w-full bg-white outline-none focus:outline-none dark:text-white dark:bg-gray-800">
                {/*header*/}
                {children.header}
                {/*body*/}
                {children.body}
                {/* <div className="flex items-start justify-between p-3 rounded-t dark:text-white dark:bg-gray-800">
                  <h5 className="text-lg font-semibold dark:bg-gray-800">Select Site</h5>
                  <p onClick={() => {
                    setSiteModal(!siteModal);
                    // setHelpModal(!helpModal)
                    setFilters({
                      tab: 'overview',
                      view: 'hourly',
                    });
                  }}>&#x2715;</p>
                </div>

                <div className="relative p-3 flex-auto dark:text-white dark:bg-gray-800">
                  <p>
                    This will help us to return historical data specific to your
                    selected sites. You can change your site selection at any
                    time via the site selector in the NavBar
                  </p>
                  <p></p>
                  <div className="my-4 ">
                    <SiteDrop
                      expression={'Site'}
                      label={'Site'}
                      filters={filters}
                      setFilters={setFilters}
                    />
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="opacity-80 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
