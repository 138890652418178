export const TatlerLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 144 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M142.295 39.3544L135.147 14.9707C137.338 14.274 139.523 12.9596 140.654 11.1785C141.99 9.07449 142.204 5.9348 139.83 3.95391C137.621 2.11004 133.843 2.18667 132.162 2.18667H123.565V2.82994H125.228V39.3544H123.586V40H132.181V39.3544H130.539V2.82994H132.162C137.061 2.84852 138.728 8.97463 136.318 12.2722C134.31 15.0195 130.621 15.6024 130.621 15.6024L137.04 39.3544H135.311V40H143.988V39.3544H142.295ZM110.77 2.17971H99.1737V2.82529H100.818V39.3521H99.1737V40H112.001V32.0672H111.347V39.3521H106.134V16.4175H109.105V20.9993H109.759V11.1994H109.105V15.7696H106.134V2.82529H111.347V10.1102H112.001V2.17971H110.77ZM75.0099 2.17971V2.82529H76.6468V39.3521H75.0076V40H87.8352V32.0672H87.1833V39.3521H81.9631V2.82529H83.6047V2.17971H75.0099ZM48.2103 2.17971V10.1102H48.8622V2.85084H52.551V39.3521H50.9118V40H59.5066V39.3521H57.8673V2.85084H61.5585V10.1102H62.2104V2.17971H48.2103ZM28.474 16.1713L30.0828 25.7599H26.7621L28.474 16.1713ZM23.6971 39.3521H22.0438V40H26.0703V39.3544H24.349L26.6355 26.4101H30.1906L32.3622 39.3521H30.5799V40H39.1747V39.3521H37.6715L30.7628 1H30.4486L23.6971 39.3521ZM0 2.17971V10.1102H0.651934V2.85084H4.3431V39.3521H2.70154V40H11.2963V39.3521H9.65706V2.85084H13.3482V10.1102H14.0002V2.17971H0Z"
      fill="black"
    />
  </svg>
);
