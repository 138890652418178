export const BALogo = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 442.8 87"
    xmlSpace="preserve"
	width="100%"
	height="100%"
  >
    <title>bon-appetit</title>
    <rect fill="none" width="418" height="87" />
    <g>
      <path
        fill="#000000"
        d="M27.2,65.2c12.6,0,23-10.9,23-24.3c0-12.8-8.6-21.5-20.2-21.5c-5.8,0-11.7,3.5-13.5,9.6V9.7
		c0-2.8,0.1-5.6,0.2-8.3c0-1.2-0.9-1.4-1.5-1.2C10.6,1.8,5.7,2.9,1.1,3.7c-1.6,0.3-1.4,2.1,0.3,2c3.9-0.2,5.7,0.9,5.7,4.2v44.8
		c0,3-0.1,6.1-0.2,9.2c0,0.8,0.3,0.9,0.6,0.9c0.4,0,0.8-0.3,1.4-0.9c1.7-1.6,3.5-3.3,5-4.7C16.3,62.9,22.3,65.2,27.2,65.2L27.2,65.2
		z M16.4,34.6c0.2-6.3,5.1-11.7,10.7-11.7c7.9,0,13,6.5,13,19.2c0,12-4.9,21.1-13,21.1c-5.3,0-10.6-3.8-10.7-12.6V34.6L16.4,34.6z
		 M101.1,41.9c0-13.5-10-22.5-23.2-22.5c-11.9,0-22.4,10.7-22.4,23.3c0,13.4,10.1,22.5,23.1,22.5C90.8,65.2,101.1,54.5,101.1,41.9
		L101.1,41.9z M65.6,42.1c0-12.1,5.1-20.5,12.6-20.5c7.7,0,13,8.3,13,20.8C91.2,54.7,86,63,78.5,63C70.6,63,65.6,54.8,65.6,42.1
		L65.6,42.1z M110.3,28.6v29.8c0,2.8-1.5,3.9-5.8,4.1c-1.4,0-1.6,2,0.1,1.9c6.6-0.2,13.9-0.2,20.6,0c1.7,0.1,1.5-1.9,0.1-1.9
		c-4.2-0.3-5.5-1.4-5.5-4.1V35.7c0.1-8.1,4.9-12,10.1-12c5.6,0,8.9,3.5,8.9,10.4v24.3c0,2.8-1,3.9-5.2,4.1c-1.4,0-1.6,2,0.1,1.9
		c6.6-0.2,13.7-0.2,20.3,0c1.7,0.1,1.5-1.9,0.1-1.9c-4.3-0.3-5.8-1.4-5.8-4.1V34.7c0-10.8-5.9-15.4-14.5-15.4
		c-5.2,0-11.3,2.8-13.8,9.8v-0.8c0-2.8,0.1-5.6,0.2-8.3c0-1.2-0.9-1.4-1.5-1.2c-4.6,1.6-9.6,2.8-14.1,3.4c-1.6,0.3-1.4,2.1,0.3,2
		C108.6,24.2,110.3,25.1,110.3,28.6L110.3,28.6z M186.1,65.4c6.2,0,11.9-3.7,14.3-8.9V57c0,5.4,3.6,8.3,8.3,8.3
		c3.9,0,6.7-1.7,8.3-4.4c0.8-1.4-0.6-2-1.2-1.1c-0.8,1.1-1.6,2.2-3.2,2.2c-1.9,0-2.9-1.5-2.9-4.4l0.1-23.5c0-11-7.9-14.6-16.2-14.6
		c-10.3,0-18,6.8-18,13.2c0,2.6,1.7,4.8,4.8,4.8c2.4,0,4.6-1.9,4.6-4.2c0-3.6-3.9-3.2-3.9-5.5c0-1.8,3.5-6,10.1-6
		c5.8,0,9.2,3.2,9.2,9.6V39L187.8,44c-10.6,4.1-13,8-13,12.2C174.8,62,180.1,65.4,186.1,65.4L186.1,65.4z M184.2,55.1
		c0-4.5,2.5-8.3,11.3-11.9l4.9-2.2v10.1c0,5-4.7,10-9.8,10C186.4,61.2,184.2,58.5,184.2,55.1L184.2,55.1z M243.4,65.2
		c12.6,0,22.9-10.9,22.9-24.3c0-12.8-8.6-21.5-20.2-21.5c-5.7,0-11.7,3.5-13.5,9.6v-0.6c0-2.8,0.1-5.6,0.2-8.3
		c0-1.2-0.9-1.4-1.5-1.2c-4.6,1.6-9.5,2.8-14.1,3.4c-1.6,0.3-1.4,2.1,0.3,2c4-0.2,5.7,0.9,5.7,4.2v52.1c0,2.8-1.5,3.9-5.8,4.1
		c-1.4,0-1.6,2,0.1,1.9c6.8-0.2,14.4-0.2,21.2,0c1.7,0.1,1.5-1.9,0.1-1.9c-4.6-0.3-6.1-1.4-6.1-4.1V61.8
		C235.7,63.9,239.9,65.2,243.4,65.2L243.4,65.2z M232.7,34.6c0.2-6.3,5.1-11.7,10.7-11.7c7.9,0,13,6.5,13,19.2
		c0,12-4.9,21.1-13,21.1c-5.3,0-10.6-3.8-10.7-12.6L232.7,34.6L232.7,34.6z M295.6,65.2c12.6,0,22.9-10.9,22.9-24.3
		c0-12.8-8.6-21.5-20.2-21.5c-5.7,0-11.7,3.5-13.5,9.6v-0.6c0-2.8,0.1-5.6,0.2-8.3c0-1.2-0.9-1.4-1.5-1.2c-4.6,1.6-9.6,2.8-14.1,3.4
		c-1.6,0.3-1.4,2.1,0.3,2c3.9-0.2,5.7,0.9,5.7,4.2v52.1c0,2.8-1.5,3.9-5.8,4.1c-1.4,0-1.6,2,0.1,1.9c6.8-0.2,14.4-0.2,21.2,0
		c1.7,0.1,1.5-1.9,0.1-1.9c-4.6-0.3-6.1-1.4-6.1-4.1V61.8C287.9,63.9,292,65.2,295.6,65.2L295.6,65.2z M284.9,34.6
		c0.2-6.3,5.1-11.7,10.7-11.7c7.9,0,13,6.5,13,19.2c0,12-4.9,21.1-13,21.1c-5.3,0-10.6-3.8-10.7-12.6V34.6L284.9,34.6z M338.8,15.1
		c5.7-1.2,10.9-3.2,15.2-5.4c3.2-1.7,4.3-3.8,4.3-5.6c0-2.2-1.6-3.7-3.8-3.7c-1.8,0-3.2,0.9-5.3,3.8c-3.2,4.6-5.9,7.6-10.6,9.6
		c-0.5,0.2-0.7,0.6-0.6,0.9C338,15.1,338.4,15.2,338.8,15.1L338.8,15.1z M345.5,65.3c8.1,0,15.2-4.8,17.9-11.9
		c0.3-0.8-0.7-1.5-1.3-0.7c-3.1,4.1-7.6,6.2-13.5,6.2c-9.4,0-15.1-8.3-15.1-18.8c0-1.5,0.1-2.9,0.3-4.2l28.4-0.2
		c1.3,0,1.6-0.8,1.5-2c-0.7-8.6-9.4-14.2-17.7-14.2c-12.3,0-21.9,10.8-21.9,23.1C324,55.6,333.6,65.3,345.5,65.3L345.5,65.3z
		 M345.7,21.6c5.2,0,8.9,3.7,8.9,9.2c0,1.6-0.8,2.5-2.8,2.6l-17.5,0.4C335.8,26.5,340,21.6,345.7,21.6L345.7,21.6z M381,65.3
		c4.6,0,8.5-2.2,11-6.5c0.7-1.2-0.6-1.9-1.3-0.8c-1,1.6-2.8,3-4.8,3c-2.9,0-4.4-2.4-4.4-5.2V24.4h9c1.6,0,1.6-2.2,0-2.2h-9V11.8
		c0-1.1-1.3-1.3-1.8-0.2c-3.1,6.6-8.5,10.1-14,11.1c-1,0.2-1,1.7,0.2,1.7h6.3v31.8C372.2,61.8,376,65.3,381,65.3L381,65.3z
		 M411.1,6.1c0-3.2-2.7-5.8-5.8-5.8c-3.2,0-5.8,2.7-5.8,5.8s2.7,5.8,5.8,5.8C408.5,12,411.1,9.4,411.1,6.1L411.1,6.1z M400.5,28.6
		v29.8c0,2.8-1.5,3.9-5.8,4.1c-1.4,0-1.6,2,0.1,1.9c6.6-0.2,14.2-0.2,20.8,0c1.7,0.1,1.5-1.9,0.1-1.9c-4.3-0.3-5.8-1.4-5.8-4.1v-30
		c0-2.8,0.1-5.6,0.2-8.3c0-1.2-0.9-1.4-1.5-1.2c-4.3,1.6-9.2,2.8-13.5,3.4c-1.6,0.3-1.4,2.1,0.3,2C399,24.2,400.5,25.1,400.5,28.6
		L400.5,28.6z M431.1,65.3c4.6,0,8.5-2.2,11-6.5c0.7-1.2-0.6-1.9-1.3-0.8c-1,1.6-2.8,3-4.8,3c-2.9,0-4.4-2.4-4.4-5.2V24.4h9
		c1.6,0,1.6-2.2,0-2.2h-9V11.8c0-1.1-1.3-1.3-1.8-0.2c-3.1,6.6-8.8,10.1-14.4,11.1c-1,0.2-1,1.7,0.2,1.7h6.7v31.8
		C422.4,61.8,426.1,65.3,431.1,65.3L431.1,65.3z"
      />
    </g>
  </svg>
);
