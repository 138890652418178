import useColorMode from '../Hooks/useColorMode';
import { HelpIcon } from '@condenast/gemini/icons';

import { GQLogo } from '../assets/svg/gq';
import { ADLogo } from '../assets/svg/ad';
import { HGLogo } from '../assets/svg/hg';
import { LCILogo } from '../assets/svg/lci';
import { GlamourLogo } from '../assets/svg/glamour';
import { PitchForkLogo } from '../assets/svg/pitch-fork';
import { SelfLogo } from '../assets/svg/self';
import { WiredLogo } from '../assets/svg/wired';
import { AllureLogo } from '../assets/svg/allure';
import { TeenVogueLogo } from '../assets/svg/teen-vogue';
import { TatlerLogo } from '../assets/svg/tatler';
import { TheNewYorkerLogo } from '../assets/svg/the-new-yorker';
import { BALogo } from '../assets/svg/ba';
import { VogueLogo } from '../assets/svg/vogue';
import { VanityFairLogo } from '../assets/svg/vanity-fair';
import { EpicuriousLogo } from '../assets/svg/epicurious';
import { TheWorldOfInteriorLogo } from '../assets/svg/the-world-of-interior';
import { CNTLogo } from '../assets/svg/cnt';
import { ThemLogo } from '../assets/svg/them';
import { HelpModal } from '../Components/help';
import { useState } from 'react';

const componentList = [
  [<GQLogo />, '/gq'],
  [<ADLogo />, '/ad'],
  [<HGLogo />, '/h&g'],
  [<LCILogo />, '/lci'],
  [<GlamourLogo />, '/glamour'],
  [<PitchForkLogo />, '/pitchfork'],
  [<SelfLogo />, '/self'],
  [<WiredLogo />, '/wired'],
  [<AllureLogo />, '/allure'],
  [<TeenVogueLogo />, '/teenvogue'],
  [<TatlerLogo />, '/tatler'],
  [<TheNewYorkerLogo />, '/tny'],
  [<BALogo />, '/ba'],
  [<VogueLogo />, '/vogue'],
  [<VanityFairLogo />, '/vanityfair'],
  [<EpicuriousLogo />, '/epi'],
  [<TheWorldOfInteriorLogo />, '/woi'],
  [<CNTLogo />, '/cnt'],
  [<ThemLogo />, '/them'],
];

const LandingPage = () => {
  const [helpModal, setHelpModal] = useState(false);
  const mode = useColorMode();
  return (
    <>
    <div className="dark:bg-gray-1 h-full">
      <header className="fixed  top-0 z-10 w-full dark:text-white dark:bg-gray-2   bg-white  border-b border-gray-5 dark:border-gray-3   transition duration-700 ease-out ">
        <div className="flex items-center  ">
          <div className="flex-none text-[1rem] leading-[1rem] tracking-tight text-black dark:text-white border-r border-gray-200   p-[20.5px] dark:border-gray-3 ">
            <a href="/">
              {mode === 'white' ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                >
                  <rect
                    width="3"
                    height="18"
                    x="10.5"
                    y="6"
                    fill="#fff"
                    rx="1.5"
                  />
                  <rect
                    width="3"
                    height="24"
                    x="19.5"
                    fill="#2EBBED"
                    rx="1.5"
                  />
                  <rect
                    width="3"
                    height="12"
                    x="1.5"
                    y="12"
                    fill="#FF4561"
                    rx="1.5"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                >
                  <rect
                    width="3"
                    height="18"
                    x="10.5"
                    y="6"
                    fill="#2E335A"
                    rx="1.5"
                  />
                  <rect
                    width="3"
                    height="24"
                    x="19.5"
                    fill="#2EBBED"
                    rx="1.5"
                  />
                  <rect
                    width="3"
                    height="12"
                    x="1.5"
                    y="12"
                    fill="#FF4561"
                    rx="1.5"
                  />
                </svg>
              )}
            </a>
          </div>

          <div className="grow tracking-tight pl-5 "></div>

          <div className="flex-none flex  items-center  tracking-tight">
            <div className="text-[1rem] items-center leading-[1rem] tracking-tight  text-black dark:text-white px-10 cursor-pointer">
              <HelpIcon size="regular" onClick={() => setHelpModal(true)}/>
            </div>
          </div>
        </div>
      </header>
      <div className="h-screen">
        <div className="shadow-md p-4 dark:text-white dark:bg-gray-2 bg-white  mt-[20px] mx-auto ">
          <div className="bg-[#fff] w-[100%] min-h-[100vh]">
            <div className="max-w-[980px] w-[80vw] m-auto pt-[60px]">
              <h1 className="text-[38px] text-center mb-[6px] font-medium">
                Beacon Global View
              </h1>
              <p className="text-[24px] text-center mb-[10px] text-[#6c6767]">
                This page is under construction
              </p>
              <p className="text-[24px] text-center mb-[40px]">
                Please select a Brand below to view its data
              </p>
              <div className="pt-[45px] flex flex-wrap justify-evenly">
                {componentList.map((comp, index) => {
                  return (
                    <a
                      href={comp[1]}
                      className="h-[30px] inline-block mr-[34px] mb-[50px]"
                      key={`${comp[1]}-${index}`}
                    >
                      {comp[0]}
                    </a>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HelpModal showModal={helpModal} closeModal={setHelpModal} mode={mode} />
    </>
  );
};

export default LandingPage;
