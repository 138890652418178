export const VanityFairLogo = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 328 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M12.8234 72H20.6255L32.2902 1.39056H35.3029V0H25.3377V1.39056H30.4362L21.3208 57.3219L13.1324 1.39056H17.6128V0H0V1.39056H2.16298L12.8234 72Z"
        fill="#252525"
      />
      <path
        d="M36.77 48.206L42.1774 14.6781L47.6621 48.206H36.77ZM28.1953 70.6094V72H37.8515V70.6094H32.9848L36.461 49.7511H47.8939L51.3701 70.6094H46.8124V72H64.5024V70.6094H62.3394L50.6748 0H42.9499L31.208 70.6094H28.1953Z"
        fill="#252525"
      />
      <path
        d="M66.1262 70.6094V72H75.0099V70.6094H70.2204V7.41631L91.6185 72H97.7984V1.39056H100.966V0H91.2322V1.39056H95.9444V50.6781L78.9496 0H65.1992V1.39056H68.3664V70.6094H66.1262Z"
        fill="#252525"
      />
      <path
        d="M102.973 70.6094V72H119.968V70.6094H117.033V1.39056H119.968V0H102.973V1.39056H105.908V70.6094H102.973Z"
        fill="#252525"
      />
      <path
        d="M130.86 70.6094V72H148.705V70.6094H145.306V1.39056H151.795L156.043 21.6309H157.434V0H122.131V21.6309H123.521L127.847 1.39056H134.259V70.6094H130.86Z"
        fill="#252525"
      />
      <path
        d="M167.939 70.6094V72H185.783V70.6094H182.384V38.3176L190.882 1.39056H193.431V0H184.084V1.39056H189.028L181.38 34.3777L171.724 1.39056H176.436V0H158.746V1.39056H160.909L171.338 38.3176V70.6094H167.939Z"
        fill="#252525"
      />
      <path
        d="M203.861 70.6094V72H222.324V70.6094H217.921V35.2275H224.719L227.036 44.2661H228.427V24.412H227.036L224.719 33.5279H217.921V1.39056H228.658L233.293 18.8498H234.684V0H203.861V1.39056H206.797V70.6094H203.861Z"
        fill="#252525"
      />
      <path
        d="M238.778 48.206L244.185 14.6781L249.67 48.206H238.778ZM230.203 70.6094V72H239.859V70.6094H234.993L238.469 49.7511H249.824L253.301 70.6094H248.743V72H266.433V70.6094H264.27L252.683 0H244.958L233.216 70.6094H230.203Z"
        fill="#252525"
      />
      <path
        d="M268.133 70.6094V72H285.206V70.6094H282.193V1.39056H285.206V0H268.133V1.39056H271.146V70.6094H268.133Z"
        fill="#252525"
      />
      <path
        d="M302.971 1.46781C308.378 1.46781 312.395 4.17167 312.395 16.3004V22.0172C312.395 32.1373 309.614 35.9227 303.743 35.9227H301.426V1.46781H302.971ZM287.289 70.6094V72H305.752V70.6094H301.348V37.3906H304.516L314.79 72.0773H327.999V70.6867H326.068L314.713 34.2232C316.953 32.9871 323.596 28.8927 323.596 18.7725C323.596 5.48498 314.79 0 303.048 0H287.289V1.39056H290.302V70.6094H287.289Z"
        fill="#252525"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="328" height="72" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
