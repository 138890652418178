import { createSearchParams } from 'react-router-dom';
import { format } from 'date-fns';

export function convertToAbbreviatedForm(labelValue, roundOf) {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(1) + ' B'
    : // Six Zeroes for Millions
    Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(1) + ' M'
    : // Three Zeroes for Thousands
    Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(1) + ' K'
    : roundOf
    ? Math.abs(Number(labelValue)).toFixed(0)
    : Math.abs(Number(labelValue)).toFixed(1);
}

export function fixedOrRoundOff(val, length = 2, round = false) {
  if (round) return Math.round(Number(val));
  else if (Math.abs(Number(val)) % 1 != 0)
    return Math.abs(Number(val)).toFixed(length);
  else return Math.abs(Number(val));
}

export const encodeSearchParams = (params) => createSearchParams(params);

export const decodeSearchParams = (searchParams) => {
  return [...searchParams.entries()].reduce((acc, [key, val]) => {
    try {
      return {
        ...acc,
        [key]: JSON.parse(val),
      };
    } catch {
      return {
        ...acc,
        [key]: val,
      };
    }
  }, {});
};

export const formatDecimalWithCommas = (input) => {
  let number = parseFloat(input); // Convert string to number
  if (isNaN(number)) return input; // Handle invalid input gracefully
  
  return Math.round(number) // Round the number to the nearest integer
      .toLocaleString(); // Add commas
}

export const formatDate = (dateString) => {
  let dateParts;
  if (dateString.includes('/')) {
    // Date format: MM/DD/YYYY
    dateParts = dateString.split('/');
  } else if (dateString.includes(' ')) {
    // Date format: MM DD HH Period or Nov 2023
    dateParts = dateString.split(' ');
  }

  // Extract date components
  let month, day, year, hour, period;
  if (dateParts.length === 3) {
    [month, day, year] = dateParts;
  } else if (dateParts.length === 4) {
    [month, day, hour, period] = dateParts;
  } else if (dateParts.length === 2) {
    // For month and year format
    [month, year] = dateParts;
    // Default day to 1st of the month
    day = '01';
  }

  // Create a Date object
  const date = new Date(`${year || new Date().getFullYear()}-${month}-${day}`);

  // Format the date
  let formattedDate;
  if (hour !== undefined && period !== undefined) {
    // Handle 12-hour format
    let hour24 = parseInt(hour);
    if (period === 'PM' && hour24 !== 12) {
      hour24 += 12;
    } else if (period === 'AM' && hour24 === 12) {
      hour24 = 0;
    }
    date.setHours(hour24);
    formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true,
    });
  } else {
    // For month and year format
    formattedDate = date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'short',
    });
  }

  return formattedDate;
};

export const numberFormating = (number, decimal = 0) => {
  return Number(number)
    .toLocaleString(undefined, {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal, // Ensure the maximum fraction digits is set to the same value as minimum to avoid showing any decimal places
    })
    .replace(/^(\d+)(\.(\d+))$/, (_, integer, __, fraction) => {
      return fraction ? integer + ',' + fraction : integer;
    });
};

export const setCookie = (name, value, days) => {
  var expires = '';
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = name + '=' + (value || '') + expires + '; path=/';
};
export const getCookie = (name) => {
  var nameEQ = name + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};
export const eraseCookie = (name) => {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
export const getDateRanges = (startDate, stopDate) => {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(format(new Date(currentDate), 'M/d/yyyy'));
    currentDate = currentDate.addDays(1);
  }
  return dateArray;
};

export const getMonthRanges = (startDate, stopDate) => {
  var dateArray = new Array();
  var currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(format(new Date(currentDate), 'MMM yyyy'));
    currentDate = currentDate.addDays(1);
  }
  let uniqData = [...new Set(dateArray)];
  return uniqData;
};

export const parseCurrency = (value) => {
  try {
    if(value && value.length > 0) {
      return '$' + convertToAbbreviatedForm(value);
    }
    return value;
  } catch(err) {
    console.log(err);
    return value;
  }
}

export const convertToPercent = (value, decimalPlaces) => {
  const val = Number(value)
  if(isNaN(val)) {
    return '0.0%';
  } else {
    return `${(value * 100).toFixed(decimalPlaces)}%`
  }
}


