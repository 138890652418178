export const HGLogo = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 730.51 248.01"
    width="100%"
    height="100%"
  >
    <title>HouseAndGarden</title>
    <path
      d="M728,89.69c-1,15.8-3.87,24.11-16.48,24.11-5.74,0-12.3-5-18.77-12,9.15-11.15,11.78-17.86,15.64-27.42,5-12.41,9.39-16.18,22.47-17V54.61h-41v2.84c9.38,0,16,2.38,16,9,0,8.51-7.37,22.77-15.37,33-6.72-7.69-13.25-17.11-18.56-24.81a216.89,216.89,0,0,1-14.24-22.83c24.18-11.38,32-17.86,32-28.69,0-12.7-11.65-19.83-27.49-19.83-19,0-34.65,14-34.65,31.26,0,7.49,3.32,16.43,7.56,24.83-14.78,7.9-31.14,19.53-31.14,36.35,0,21.9,15.93,31.87,38.95,31.87a59.47,59.47,0,0,0,34.21-10.6c7,6.15,15,10.6,23.54,10.6,21.58,0,30.45-16.35,30.72-37.83ZM662.31,5.81c9.42,0,12.44,5.31,12.44,17.23,0,14.43-3.91,18.54-18.43,25.87-4.38-8.8-7.91-18.4-7.91-26.62C648.41,14.48,651.77,5.81,662.31,5.81Zm-4.87,114.7c-16.75,0-30.45-11-30.45-32.35,0-11.43,3.6-19,10.34-24.86a204.35,204.35,0,0,0,14.15,21.91c4.86,6.63,13,19.58,23.26,29.42C669.37,118.41,664,120.51,657.44,120.51Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M127.3,10.4c1.79-1.8,5-2.19,12.15-2.19h5.48V5.62H82.22V8.21h4.56c7.18,0,10.33.39,12.16,2.19,2.39,2.39,2.81,5.78,2.81,21.92V62.69h-56V32.32c0-16.14.4-19.53,2.82-21.92,1.8-1.8,5-2.19,12.16-2.19h4.56V5.62H2.58V8.21H8.05c7.18,0,10.34.39,12.16,2.19C22.61,12.79,23,16.18,23,32.32V98.49c0,16.15-.39,19.53-2.82,21.93-1.79,1.79-5,2.19-12.16,2.19H2.58v2.59H65.29v-2.59H60.73c-7.18,0-10.33-.4-12.16-2.19-2.39-2.4-2.82-5.78-2.82-21.93V65.88h56V98.49c0,16.15-.39,19.53-2.81,21.93-1.8,1.79-5,2.19-12.16,2.19H82.22v2.59h62.71v-2.59h-5.48c-7.17,0-10.33-.4-12.15-2.19-2.4-2.4-2.82-5.78-2.82-21.93V32.32C124.48,16.18,124.87,12.79,127.3,10.4Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M468.45,103.25c0-18.15-14.05-22-32.89-27.82-21.66-6.73-43.08-12.49-43-38.15,0-20.11,15-34,36.27-34,18.54,0,30.16,11.08,36.95,11.08,4.18,0,6-3.18,6.18-8.76h2.58V47.78h-2.58C467.53,21.1,450.12,5.87,429,5.87c-16.54,0-22.32,10.14-22.32,18.91,0,16.36,15.87,21,33.09,26.43,20.29,6.35,44.11,13.05,44.11,37.74,0,23.33-13.93,38.62-41.66,38.62-20.77,0-28.7-11.57-39-11.57-4.37,0-7,4-7.37,9.17H393V74h2.59c2.6,23.12,18.74,51,46.64,51C462.74,125,468.45,116.2,468.45,103.25Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M203.87,127.57c33.7,0,59.61-27.28,59.61-62.16S237.57,3.24,203.87,3.24s-59.61,27.29-59.61,62.17,25.91,62.16,59.61,62.16m0-2.59c-22.92,0-35.69-21.37-35.69-59.57S181,5.83,203.87,5.83s35.7,21.48,35.7,59.58S226.79,125,203.87,125Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M303.31,32.32c0-16.14.41-19.52,2.82-21.92,1.8-1.79,5-2.19,12.16-2.19h5.47V5.62H260.14V8.21h5.48c7.17,0,10.34.39,12.15,2.19,2.41,2.38,2.82,5.78,2.82,21.92l0,53.08c0,16.54,6.55,42.17,50.19,42.17,21.59,0,42.39-6.78,42.39-41.59V35.51c0-21.06,6.29-27.3,20.65-27.3V5.62H348.87V8.21c14.25,0,20.64,6.24,20.64,27.3v50.7c0,34.63-22.57,38-34.8,38-19.94,0-31.46-11.9-31.46-35.61Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M589.55,78.9c-1.4,24.52-15.18,43.71-39.3,43.71H528.19V63.74H538c15.14,0,23.71,5.26,25.71,27.78h2.59V32.74h-2.59c-2,22.52-10.57,27.82-25.71,27.82h-9.79V8.21h21.43c22.13,0,34.29,14.81,36.88,37.5h2.58V5.62H485V8.21h5.49c7.17,0,10.33.39,12.15,2.19,2.4,2.39,2.82,5.78,2.82,21.93V98.49c0,16.15-.39,19.53-2.82,21.93-1.79,1.79-5,2.19-12.15,2.19H485v2.59H592.13V78.9Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M552.71,138.71c22.12,0,34.25,13.26,36.84,35.95h2.58V136.13H485v2.58h5.49c7.17,0,10.33.39,12.15,2.19,2.4,2.39,2.82,5.77,2.82,21.92v59.85c0,16.15-.39,19.53-2.82,21.93-1.79,1.79-5,2.19-12.15,2.19H485v2.59H592.13v-44h-2.61c-1.4,24.52-15.15,41.4-39.27,41.4H528.19V190.62H538c15.14,0,23.71,3.72,25.71,26.24h2.59v-55.7h-2.59c-2,22.52-10.57,26.29-25.71,26.29h-9.79V138.71Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M360.83,220.1c0,11.84-2,24.92-10.34,24.92-3.77,0-5.06-5-5.72-8.17-1.07-5.14-1.34-10.62-2.56-18.07-2.71-16.54-12.92-24.7-27.53-27.77,22.51-3.39,32-13.5,32-27,0-15.61-10.36-27.92-39.2-27.92H243.5v2.58H246c6.84,0,10.12.6,11.56,2.3,2.07,2.45,2.65,6.07,2.65,40.42v23.66c0,34.35-.58,36.81-2.65,39.26-1.44,1.69-4.72,2.44-11.56,2.44H243.5v2.59h57.85v-2.59h-4.23c-6.84,0-10.12-.75-11.57-2.44-2.06-2.45-2.59-4.91-2.59-39.26V192.36h13.71c14,0,18,5.21,19.11,17.62.44,5.14.78,8.2,1.39,15.18,1.3,14.66,9.71,25.7,24.43,25.7,19.31,0,22-14,22-30.76Zm-58-30.94H283V139c5.09-.19,14.51-.23,18.85-.23,15.48,0,20.51,9.2,20.51,25.31C322.32,178.21,320.55,189.16,302.82,189.16Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M425.05,249.38H365.37V246.8h4.23c6.84,0,10.12-.76,11.57-2.45,2.07-2.45,2.64-5.91,2.64-40.26V181.43c0-34.35-.57-38-2.64-40.42-1.45-1.7-4.73-2.3-11.57-2.3h-4.23v-2.58H425c38.41,0,57.76,26.8,57.76,56.63s-19.08,56.62-57.74,56.62m-4.79-110.63c-4.73,0-10.13,0-13.71.22V246.42c3.59.19,9,.19,13.73.19,26.61,0,37.76-20.27,37.76-53.85s-11.16-54-37.78-54"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M113.9,218.57c0-13.59.89-16.43,2.45-18.12s4.77-2.46,11.56-2.46h2.66v-2.62h-56V198h3c6.8,0,9.69.77,11.25,2.46s2.44,4.53,2.44,18.12c0,14.35-8.29,30.06-27.48,30.06-23.22,0-33.6-28.27-33.6-55.84,0-35.67,13.79-57.44,36.84-57.44,22.09,0,35.48,21.22,39.25,40.27h2.67V133.91h-2.68c-.56,5.67-3.81,9.82-7.15,9.82-5.47,0-14.16-11-32.09-11-37.93,0-60.74,28.32-60.74,60,0,30.36,24.09,58.44,57.5,58.44,21.14,0,26.12-13,34.43-13,5.59,0,11.18,3.35,13.2,10.37h2.42Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M691.73,136.13v2.57c13.22.75,18.38,8.3,18.38,25.85v49.8L646,136.13H607.76v2.58c8.88.18,14,4,18.71,10.08l1.19,1.43V221.1c0,17.55-5.09,25.69-18.38,25.69v2.59h40.46v-2.59c-13.3,0-18.38-8.14-18.38-25.69V154.67l79.33,95.51h3l.17-85.63c0-17.55,5.17-25.1,18.38-25.85v-2.57Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M220.51,225.73l-36.79-91.86h-1.07L146,225.79c-5.46,13.69-11.76,21-23.76,21v2.59h39.41v-2.59c-8.73,0-14.27-3.47-14.27-8.93a25.12,25.12,0,0,1,1.85-9.67l6.55-17h33.6l7.25,18.5a21.67,21.67,0,0,1,1.89,8.38c0,4.15-2.2,8.68-10.72,8.68H184v2.59h56.79v-2.59C232.34,246.79,227.23,241.69,220.51,225.73ZM157,208.05l15.46-40,15.67,40Z"
      transform="translate(-2.58 -3.21)"
    />
    <path
      d="M729,151.66a4.14,4.14,0,1,1,4.12-4.15A4.15,4.15,0,0,1,729,151.66Zm0-7.7a3.56,3.56,0,1,0,3.55,3.55A3.45,3.45,0,0,0,729,144Zm.6,3.76,1.4,2.09h-.58l-1.33-2.1H728v2.1h-.53v-4.65h2c1.22,0,1.62.45,1.62,1.28S730.45,147.66,729.57,147.72Zm-.25-2.09H728v1.61h1.4c.54,0,1.1-.11,1.1-.81S729.89,145.63,729.32,145.63Z"
      transform="translate(-2.58 -3.21)"
    />
    <path d="M733.09,252.46" transform="translate(-2.58 -3.21)" />
  </svg>
);
