export const ADLogo = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 110 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 72H12.3541V71.2493H6.52829L14.5537 48.9512H37.0317L45.0571 71.258H38.2698V72H57.3937L31.4429 0L5.79951 71.2317H0V72ZM14.8083 48.2488L25.7839 17.7673L36.7595 48.2488H14.8083ZM57.139 72H74.2346C93.9907 72 109.989 60.3044 109.989 36.6454C109.989 13.2937 94.702 1.18537 74.0327 1.18537H49.8512V1.89659H57.1215C57.1215 2.23024 57.1215 71.9824 57.1215 71.9824L57.139 72ZM68.3868 71.2317C68.3868 70.9244 68.3868 2.7439 68.3868 1.91415H72.7771C77.9444 1.91415 82.282 2.17756 85.4824 3.72732C94.1663 7.85854 97.1649 18.338 97.1649 36.6278C97.1649 53.9868 92.8317 64.2381 85.6976 68.8873C82.3873 70.9507 77.839 71.2317 73.8176 71.2317H68.3868Z"
        fill="#1D1D1B"
      />
    </svg>
  );
};
