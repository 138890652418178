export const ThemLogo = () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1579.16 499.69"
    width="100%"
    height="100%"
  >
    <title>them</title>
    <path d="M0,137.19H56.15V81L200.45,5V137.19H263V260.86H200.45V302.8c0,56.16,2.84,56.87,62.54,56.87V492.59H211.11c-127.24,0-155-38.39-155-177V260.86H0Z" />
    <path d="M280.92,0H423.08V179.83c19.9-31.27,50.47-49.75,86.71-49.75,68.95,0,118,45.49,118,126.52v236H480.66V295.69c0-26.3-8.54-39.09-27-39.09s-28.43,13.51-28.43,39.09v196.9H280.92Z" />
    <path d="M645.72,314.88c0-100.21,75.34-184.8,188.36-184.8,110.89,0,188.36,76.77,188.36,183.39v46.2H784.32c11.38,22,31.28,31.27,51.18,31.27,21.33,0,32-5.68,44.78-18.48h141.45c-31.27,81-82.45,127.23-186.23,127.23C711.11,499.69,645.72,419.38,645.72,314.88ZM785,266.56H888.81c-9.95-18.49-28.43-27.73-51.17-27.73S796.41,248.79,785,266.56Z" />
    <path d="M1040.37,137.19h144.29V187c21.33-37.68,54-56.87,93.83-56.87,44.78,0,76.06,18.48,92.4,54,20.61-34.83,53.31-54,93.83-54,69,0,114.44,45.49,114.44,126.52v236H1433.44V295.69c0-26.3-6.39-39.09-24.88-39.09s-26.29,13.51-26.29,39.09v196.9H1236.55V295.69c0-26.3-6.4-39.09-24.88-39.09s-26.3,13.51-26.3,39.09v196.9h-145Z" />
  </svg>
);
