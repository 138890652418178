import { useEffect, useState } from 'react';
const useColorMode = () => {
  const [mode, setMode] = useState(null);
  useEffect(() => {
    if (
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
    ) {
      setMode('white');
    } else {
      setMode('dark');
    }
  }, [
    window.matchMedia('(prefers-color-scheme: dark)'),
    window.matchMedia('(prefers-color-scheme: light)'),
  ]);

  return mode;
};

export default useColorMode;
